.annuities {
    color: var(--text-color);
    padding: calc(var(--nav-size) * 0.4);
    text-align: center;
    min-height: 83vh;
}

.annuities-header {
    margin-bottom: calc(var(--nav-size) * 0.5);
}

.annuities-title {
    font-size: calc(var(--nav-size) * 0.5);
    font-weight: bold;
    margin-bottom: calc(var(--nav-size) * 0.2);
}

.annuities-subtitle {
    font-size: calc(var(--nav-size) * 0.3);
    color: var(--text-color-secondary);
}

.annuities-inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: calc(var(--nav-size) * 0.2);
}

.annuities-find {
    display: flex;
    align-items: center;
}

.annuities-find-label {
    font-size: calc(var(--nav-size) * 0.3);
    margin-right: calc(var(--nav-size) * 0.2);
}

.annuities-find-dropdown {
    font-size: calc(var(--nav-size) * 0.3);
}

.annuities-find-dropdown.id {
    background-color: var(--bg, #f9f9f9);
    color: var(--text-color);
}

.toggle-new-annuity-btn {
    background-color: var(--primary-color);
    color: var(--text-color-inv);
    padding: calc(var(--nav-size) * 0.2) calc(var(--nav-size) * 0.4);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: calc(var(--nav-size) * 0.3);
    transition: background-color 0.3s ease;
}

.toggle-new-annuity-btn:hover {
    background-color: var(--primary-color-dark);
}

.annuities-newannuity {
    margin-bottom: calc(var(--nav-size) * 0.2);
}

.annuities-output {
    margin-bottom: calc(var(--nav-size) * 0.2);
}

.annuities-resulttime {
    margin-bottom: calc(var(--nav-size) * 0.4);
}

.annuities-resulttime-text {
    font-size: calc(var(--nav-size) * 0.3);
    font-weight: bold;
}

.annuities-description {
    text-align: left;
    font-size: calc(var(--nav-size) * 0.25);
    margin-bottom: calc(var(--nav-size) * 1);
}

.annuities-description p {
    margin-bottom: calc(var(--nav-size) * 0.5);
}

.annuities-description ul {
    list-style-type: none;
    padding: 0;
}

.annuities-description li {
    margin-bottom: calc(var(--nav-size) * 0.2);
}

.newAnnuity-space {
    display: flex;
    justify-content: center;
    margin-top: calc(var(--nav-size) * 1);
}

.view-source-btn {
    background-color: var(--primary-color);
    color: var(--text-color-inv);
    padding: calc(var(--nav-size) * 0.2) calc(var(--nav-size) * 0.4);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: calc(var(--nav-size) * 0.3);
    transition: background-color 0.3s ease;
}

.view-source-btn:hover {
    background-color: var(--primary-color-dark);
}