.pay-container {
  min-height: 87vh;
  padding: calc(var(--nav-size) * 0.5);
  padding-top: calc(var(--nav-size) * 1);
}

.pay-header {
  text-align: center;
  margin-bottom: calc(var(--nav-size) * 0.05);
}

.pay-header h1 {
  font-size: calc(var(--nav-size) * 0.4);
  color: var(--text-color);
  margin-bottom: 0;
}

.pay-header p {
  font-size: calc(var(--nav-size) * 0.2);
  color: var(--text-color-accent);
}

.pay-content {
  display: flex;
  justify-content: center;
  margin-top: calc(var(--nav-size) * 0.03);
}

.pay-plan-container {
  color: var(--text-color);
  font-size: calc(var(--nav-size) * 0.3);
  width: 100%;
}

.pay-plan-options {
  display: flex;
  gap: calc(var(--nav-size) * 0.9);
}

.pay-plan-option {
  padding: calc(var(--nav-size) * 0.3) calc(var(--nav-size) * 0.4);
  border: calc(var(--nav-size) * 0.01) solid var(--text-color);
  background-color: var(--bg-page);
  color: var(--text-color);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: var(--border-radius);
  margin-left: calc(var(--nav-size) * 0.3);
  font-size: calc(var(--nav-size) * 0.3);
}

.pay-plan-option.selected {
  background-color: var(--bg-mint);
  color: var(--text-color);
  border: calc(var(--nav-size) * 0.05) solid var(--text-color);
}

.pay-plan-option:hover {
  background-color: var(--bg-nav2);
  color: var(--text-color-accent);
}

.pay-plan-descriptions {
  margin-top: calc(var(--nav-size) * 1);
  padding-left: calc(var(--nav-size) * 0.3);
}

.pay-plan-description {
  font-size: calc(var(--nav-size) * 0.25);
  color: var(--text-color);
}

.pay-details-container {
  margin-top: calc(var(--nav-size) * 0.3);
  color: var(--text-color);
  font-size: calc(var(--nav-size) * 0.3);
  width: 100%;
}

.form-group {
  min-width: 60%;
  border: var(--border);
  border-radius: var(--border-radius);
  padding: calc(var(--nav-size) * 0.3);
  margin: calc(var(--nav-size) * 0.3);
  height: calc(var(--nav-size) * 3.3);
}

.form-group label {
  display: block;
  margin-bottom: calc(var(--nav-size) * 0.0625);
}

.pay-button {
  padding: calc(var(--nav-size) * 0.125) calc(var(--nav-size) * 0.25);
  background-color: var(--bg-mint);
  color: var(--text-color-inverse, white);
  border: var(--border);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: var(--border-radius);
}

.pay-button:hover {
  background-color: var(--bg-nav2);
  transform: translateY(calc(var(--nav-size) * -0.025));
}

.card-element {
  padding: calc(var(--nav-size) * 0.125);
  border: var(--border);
  border-radius: var(--border-radius);
  margin-bottom: calc(var(--nav-size) * 0.125);
}

.pay-error {
  color: var(--color-error, #e74c3c);
  margin-bottom: calc(var(--nav-size) * 0.125);
}

.previous-payment-methods-container {
  margin-top: calc(var(--nav-size) * 0.3);
  color: var(--text-color);
  font-size: calc(var(--nav-size) * 0.3);
  width: 100%;
}

.previous-payment-methods {
  margin-top: calc(var(--nav-size) * 0.3);
}

.payment-method {
  padding: calc(var(--nav-size) * 0.3);
  border: var(--border);
  border-radius: var(--border-radius);
  margin-bottom: calc(var(--nav-size) * 0.3);
  background-color: var(--bg-page);
}

/* Responsive adjustments */
@media (max-width: 768px) {
.pay-container {
  min-height: 87vh;
  padding: calc(var(--nav-size) * 0.5);
  padding-top: calc(var(--nav-size) * 1);
}

.pay-header {
  text-align: center;
  margin-bottom: calc(var(--nav-size) * 0.05);
}
}