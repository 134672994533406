.admin-table-wrapper {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
}

.admin-table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--bg-1);
  color: var(--text-color);
  font-size: calc(var(--nav-size) * 0.2);
  text-align: left;
}

.admin-table th,
.admin-table td {
  padding: 10px;
  border: 1px solid var(--border);
  word-wrap: break-word;
}

.admin-table th {
  background-color: var(--bg-2);
  font-weight: bold;
  text-transform: uppercase;
}

.admin-table tr:hover {
  background-color: var(--bg-mint);
}

.admin-search {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid var(--border);
  border-radius: 5px;
  background-color: var(--bg-1);
  color: var(--text-color);
  font-size: calc(var(--nav-size) * 0.2);
}

.table-scroll-container {
  max-height: 400px;
  overflow-y: auto;
}

.sortable-header {
  cursor: pointer;
  position: relative;
}

.sortable-header.asc::after {
  content: "▲";
  position: absolute;
  right: 5px;
  font-size: 0.8em;
}

.sortable-header.desc::after {
  content: "▼";
  position: absolute;
  right: 5px;
  font-size: 0.8em;
}
