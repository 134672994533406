.admin-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: linear-gradient(-45deg, var(--bg-mint), var(--bg-blue));
    background-size: 400% 400%;
    pointer-events: all;
}

.admin-section-tile {
    font-family: 'CutiveMono-Regular';
    text-align: center;
    margin: calc(var(--nav-size)*1) auto;
    width: 80%;
    max-width: 100%;
    color: var(--text-color);
    height: 100%;
}

.admin-section-tile h2 {
    margin-bottom: calc(var(--nav-size)*.025);
    font-size: calc(var(--nav-size)*.3);
    text-shadow: calc(var(--nav-size)*.025) calc(var(--nav-size)*.02) var(--border);
}

.admin-table {
    width: 100%;
    table-layout: fixed;
    overflow-x: auto;
    border-collapse: collapse;
    background-color: var(--bg-1);
    overflow: hidden;
    font-size: calc(var(--nav-size)*.3);
    text-align: center;
    color: var(--text-color);
    border: var(--nav-size)*.01 solid var(--text-color);
}

.admin-table thead {
    display: table;
    width: calc(100% - calc(var(--nav-size)*.11));
}

.admin-table tbody {
    display: block;
    max-height: 82%; /* Adjust as needed */
    /* overflow-y: scroll; */
}

.admin-table th, .admin-table td {
    word-wrap: break-word;
    white-space: normal;
    border: calc(var(--nav-size)*.01) solid var(--text-color);
    text-align: left;
    text-wrap: break-word;    
    width: 20%;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.admin-table th {
    border: none;
    text-transform: uppercase;
    font-size: calc(var(--nav-size)*.17);
    font-weight: bold;
    text-align: center;
    background-color: var(--bg-1);
}

.admin-table tr:hover {
    background-color: var(--bg-mint);
}

.admin-table-row {
    width: auto;
    display: table;
    width: 100%;
    table-layout: fixed;
}

.admin-table-row-text {
    width: auto;
    font-size: calc(var(--nav-size)*.15);
}

.table-scroll-container {
  max-height: 90vh;
  overflow-y: auto;
}

/* Collapsible Sections */
.collapsible-section {
    margin: 20px 0;
    border: 1px solid var(--border);
    border-radius: 5px;
    background-color: var(--bg-1);
    overflow: hidden;
}

.collapsible-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
    background-color: var(--bg-2);
    transition: background-color 0.3s ease;
}

.collapsible-header:hover {
    background-color: var(--bg-mint);
}

.collapsible-header h3 {
    margin: 0;
    font-size: calc(var(--nav-size)*.2);
}

.collapse-icon {
    font-size: calc(var(--nav-size)*.2);
    transition: transform 0.3s ease;
}

.collapse-icon.collapsed {
    transform: rotate(180deg);
}

.collapsible-content {
    padding: 15px;
}

/* Visitor Map */
.visitor-map-container {
    width: 100%;
    height: 500px;
    position: relative;
    border: 1px solid var(--border);
    border-radius: 5px;
    margin: 15px 0;
    overflow: hidden;
    background-color: var(--bg-1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.map-controls {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
}

.map-controls button {
    background-color: var(--bg-2);
    border: 1px solid var(--border);
    color: var(--text-color);
    padding: 5px 10px;
    margin-left: 5px;
    cursor: pointer;
    border-radius: 3px;
}

.map-controls button:hover {
    background-color: var(--bg-mint);
}

.visitor-map {
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 0.3s ease;
    transform-origin: center;
    max-width: 100%; /* Ensure the map scales properly */
    max-height: 100%;
    cursor: grab; /* Add grab cursor for panning */
}

.visitor-map:active {
    cursor: grabbing; /* Change cursor to grabbing when dragging */
}

.world-map-placeholder {
    width: 100%;
    height: 100%;
    position: relative;
    background-image: url('../../assets/world-map.svg'); /* Corrected path */
    background-size: contain; /* Ensure the map fits within the container */
    background-position: center;
    background-repeat: no-repeat;
}

.visitor-dot {
    width: 14px;
    height: 14px;
    background: linear-gradient(135deg, #4caf50, #81c784); /* Modern gradient */
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 5;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.visitor-dot:hover {
    transform: translate(-50%, -50%) scale(1.3);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 6;
    background: linear-gradient(135deg, #388e3c, #66bb6a); /* Darker gradient on hover */
}

.visitor-popup {
    position: absolute;
    background-color: var(--bg-mint); /* Match app background */
    color: var(--text-color);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translate(-50%, -120%);
    z-index: 10;
    pointer-events: none;
    white-space: nowrap;
    font-size: calc(var(--nav-size) * 0.15);
}

.visitor-popup::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: var(--bg-mint) transparent transparent transparent; /* Match popup background */
}

@media (orientation: portrait) {
    .visitor-map-container {
        height: 300px; /* Adjust height for portrait mode */
    }
}

/* Admin search */
.admin-search {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid var(--border);
    border-radius: 5px;
    background-color: var(--bg-1);
    color: var(--text-color);
    font-size: calc(var(--nav-size)*.15);
}

/* Date filter */
.date-filter {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.date-filter label {
  font-size: calc(var(--nav-size) * 0.2);
  color: var(--text-color);
}

.date-filter input {
  padding: 5px;
  font-size: calc(var(--nav-size) * 0.2);
  border: 1px solid var(--border);
  border-radius: 5px;
  background-color: var(--bg-1);
  color: var(--text-color);
}