.planit-dataresult {
  border-radius: var(--border-radius);
  margin-top: calc(var(--nav-size) * 0.25);
  padding: calc(var(--nav-size) * 0.25);
  color: var(--text-color);
  font-size: calc(var(--nav-size) * 0.25);
  min-height: 10vh;
  box-shadow: 
    0 calc(var(--nav-size) * 0.05) calc(var(--nav-size) * 0.2) rgba(0, 0, 0, 0.07),
    0 calc(var(--nav-size) * 0.01) calc(var(--nav-size) * 0.05) rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: calc(var(--nav-size) * 0.15);
  border: calc(var(--nav-size) * 0.003) solid rgba(180, 180, 180, 0.2);
  backdrop-filter: blur(calc(var(--nav-size) * 0.1));
}

.planit-dataresult:hover {
  transform: translateY(calc(var(--nav-size) * -0.05));
  box-shadow: 
    0 calc(var(--nav-size) * 0.15) calc(var(--nav-size) * 0.3) rgba(0, 0, 0, 0.09),
    0 calc(var(--nav-size) * 0.02) calc(var(--nav-size) * 0.08) rgba(0, 0, 0, 0.06);
  border-color: rgba(var(--bg-mint-rgb, 79, 156, 249), 0.3);
}

.planit-dataresult-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: calc(var(--nav-size) * 0.2);
  border-bottom: calc(var(--nav-size) * 0.01) solid rgba(var(--bg-mint-rgb, 79, 156, 249), 0.15);
  position: relative;
}

.planit-dataresult-header::after {
  content: "";
  position: absolute;
  bottom: calc(var(--nav-size) * -0.01);
  left: 0;
  width: 40%;
  height: calc(var(--nav-size) * 0.01);
  background: linear-gradient(90deg, var(--fg-mint, #4f9cf9), transparent);
  border-radius: var(--border-radius);
}

.planit-dataresult-footer {
  border-top: calc(var(--nav-size) * 0.01) solid rgba(var(--bg-mint-rgb, 79, 156, 249), 0.15);
  padding-top: calc(var(--nav-size) * 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.planit-dataresult-footer::before {
  content: "";
  position: absolute;
  top: calc(var(--nav-size) * -0.01);
  right: 0;
  width: 40%;
  height: calc(var(--nav-size) * 0.01);
  background: linear-gradient(-90deg, var(--fg-mint, #4f9cf9), transparent);
  border-radius: var(--border-radius);
}

.planit-dataresult-content {
  padding: calc(var(--nav-size) * 0.15) 0;
  position: relative;
  z-index: 1;
}

.planit-dataresult-actions {
  display: flex;
  gap: calc(var(--nav-size) * 0.15);
  align-items: center;
}

/* Image styling */
.planit-dataresult-image {
  max-width: calc(min(var(--nav-size) * 6, 100%));
  height: auto;
  border-radius: calc(var(--nav-size) * 0.15);
  padding: calc(var(--nav-size) * 0.05);
  margin: calc(var(--nav-size) * 0.1) auto;
  box-shadow: 
    0 calc(var(--nav-size) * 0.05) calc(var(--nav-size) * 0.15) rgba(0, 0, 0, 0.12),
    0 calc(var(--nav-size) * 0.02) calc(var(--nav-size) * 0.05) rgba(0, 0, 0, 0.08);
  background: linear-gradient(135deg, var(--bg), #ffffff);
  border: calc(var(--nav-size) * 0.01) solid rgba(255, 255, 255, 0.8);
  display: block;
  transition: all 0.3s ease;
}

.planit-dataresult-image:hover {
  transform: scale(1.02);
  box-shadow: 
    0 calc(var(--nav-size) * 0.08) calc(var(--nav-size) * 0.2) rgba(0, 0, 0, 0.16),
    0 calc(var(--nav-size) * 0.03) calc(var(--nav-size) * 0.08) rgba(0, 0, 0, 0.1);
}

.planit-dataresult-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

/* Voting section */
.planit-dataresult-voting {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(var(--nav-size) * 0.25);
  width: 100%;
  padding: calc(var(--nav-size) * 0.05);
}

.planit-dataresult-votecomment-holder {
  display: flex;
  justify-content: center;
  min-width: calc(var(--nav-size) * 3);
}

.planit-dataresult-votecomment-link {
  text-decoration: none;
  color: var(--text-color);
  opacity: 0.8;
  transition: opacity 0.2s;
}

.planit-dataresult-votecomment-link:hover {
  opacity: 1;
}

.planit-dataresult-votecomment {
  font-size: calc(var(--nav-size) * 0.225);
  padding: calc(var(--nav-size) * 0.1);
}

/* User info styling */
.planit-dataresult-created {
  display: flex;
  align-items: center;
  gap: calc(var(--nav-size) * 0.15);
}

.planit-dataresult-created-user {
  font-weight: 500;
  font-size: calc(var(--nav-size) * 0.25);
}

.planit-dataresult-created-date {
  font-size: calc(var(--nav-size) * 0.2);
  color: var(--text-color-accent, rgba(0, 0, 0, 0.6));
}

/* Button styling */
.planit-dataresult-share-btn,
.planit-dataresult-manageplan-btn,
.planit-dataresult-fav-btn,
.planit-dataresult-unfav-btn {
  background-color: rgba(var(--bg-alt-rgb, 245, 245, 245), 0.6);
  border: none;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: calc(var(--nav-size) * 0.05);
  transition: all 0.2s ease;
  font-size: calc(var(--nav-size) * 0.25);
  padding: calc(var(--nav-size) * 0.1) calc(var(--nav-size) * 0.15);
  border-radius: var(--border-radius);
  box-shadow: 0 calc(var(--nav-size) * 0.01) calc(var(--nav-size) * 0.03) rgba(0, 0, 0, 0.05);
}

.btn-icon {
  font-size: calc(var(--nav-size) * 0.3);
}

.btn-text {
  display: inline-block;
  font-size: calc(var(--nav-size) * 0.25);
}

.planit-dataresult-share-btn:hover,
.planit-dataresult-manageplan-btn:hover {
  background-color: rgba(var(--bg-mint-rgb, 79, 156, 249), 0.1);
  transform: translateY(calc(var(--nav-size) * -0.025));
  box-shadow: 0 calc(var(--nav-size) * 0.02) calc(var(--nav-size) * 0.05) rgba(0, 0, 0, 0.1);
}

.planit-dataresult-fav-btn {
  color: var(--color-favorite, #e74c3c);
  background-color: rgba(231, 76, 60, 0.08);
}

.planit-dataresult-unfav-btn:hover {
  color: var(--color-favorite, #e74c3c);
  background-color: rgba(231, 76, 60, 0.08);
  transform: translateY(calc(var(--nav-size) * -0.025));
}

/* Goal content styling */
.planit-dataresult-goal {
  padding: calc(var(--nav-size) * 0.2);
  transition: all 0.2s ease;
  border-radius: var(--border-radius);
  box-shadow: inset 0 0 calc(var(--nav-size) * 0.1) rgba(0, 0, 0, 0.015);
}

.planit-dataresult-goal:hover {
  background: rgba(var(--bg-alt-rgb, 245, 245, 245), 0.7);
  box-shadow: inset 0 0 calc(var(--nav-size) * 0.15) rgba(0, 0, 0, 0.025);
}

.planit-dataresult-goalbutton-text {
  font-size: calc(var(--nav-size) * 0.275);
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.6;
  font-weight: 400;
  color: var(--text-color);
}

/* Thumb buttons styling */
.planit-dataresult-agree,
.planit-dataresult-disagree,
.planit-dataresult-agreeACT,
.planit-dataresult-disagreeACT {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(var(--nav-size) * 0.65);
  height: calc(var(--nav-size) * 0.65);
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 
    0 calc(var(--nav-size) * 0.02) calc(var(--nav-size) * 0.05) rgba(0, 0, 0, 0.1),
    inset 0 calc(var(--nav-size) * 0.01) calc(var(--nav-size) * 0.02) rgba(255, 255, 255, 0.6);
}

.planit-dataresult-agree,
.planit-dataresult-disagree {
  background-color: var(--bg-alt, rgba(0, 0, 0, 0.05));
}

.planit-dataresult-agreeACT {
  background-color: var(--green0, rgba(46, 204, 113, 0.2));
  border: calc(var(--nav-size) * 0.01) solid var(--green0, rgba(46, 204, 113, 0.5));
}

.planit-dataresult-disagreeACT {
  background-color: var(--red1, rgba(231, 76, 60, 0.2));
  border: calc(var(--nav-size) * 0.01) solid var(--red1, rgba(231, 76, 60, 0.5));
}

.planit-dataresult-agree:hover,
.planit-dataresult-disagree:hover {
  transform: translateY(calc(var(--nav-size) * -0.05));
}

.planit-dataresult-thumb {
  width: calc(var(--nav-size) * 0.3);
  height: calc(var(--nav-size) * 0.3);
  object-fit: contain;
}

/* File navigation styling */
.planit-dataresult-file-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: calc(var(--nav-size) * 0.15);
  padding: calc(var(--nav-size) * 0.1) 0;
}

.nav-button {
  background-color: var(--bg-nav2);
  color: var(--text-color);
  border: none;
  padding: calc(var(--nav-size) * 0.1) calc(var(--nav-size) * 0.15);
  font-size: calc(var(--nav-size) * 0.225);
  cursor: pointer;
  border-radius: calc(var(--nav-size) * 0.1);
  transition: background-color 0.2s, transform 0.2s;
}

.nav-button:hover {
  background-color: var(--bg-nav2-hover);
  transform: translateY(calc(var(--nav-size) * -0.025));
}

.file-counter {
  font-size: calc(var(--nav-size) * 0.225);
  color: var(--text-color-accent);
}

/* Badge styling - enhanced for modern look */
.badge {
  position: relative;
  width: calc(var(--nav-size) * 0.5);
  height: calc(var(--nav-size) * 0.5);
  margin-left: calc(var(--nav-size) * 0.1);
  margin-right: calc(var(--nav-size) * 0.15);
  display: inline-block;
  transition: transform 0.3s ease;
}

.badge:hover {
  transform: scale(1.1) rotate(5deg);
}

.badge-circle {
  width: calc(var(--nav-size) * 0.4);
  height: calc(var(--nav-size) * 0.4);
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 
    0 calc(var(--nav-size) * 0.03) calc(var(--nav-size) * 0.08) rgba(0, 0, 0, 0.25),
    inset 0 0 calc(var(--nav-size) * 0.05) rgba(255, 255, 255, 0.5);
  z-index: 2;
}

.badge-circle::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: inherit;
  filter: blur(calc(var(--nav-size) * 0.025));
  opacity: 0.7;
  z-index: -1;
}

/* Gold badge - Premium users */
.gold-badge-circle {
  background: linear-gradient(135deg, #ffd700 0%, #ffc800 25%, #d4af37 50%, #ffbf00 75%, #ffb700 100%);
  border: calc(var(--nav-size) * 0.01) solid rgba(255, 215, 0, 0.8);
  box-shadow: 
    0 calc(var(--nav-size) * 0.03) calc(var(--nav-size) * 0.06) rgba(0, 0, 0, 0.3),
    0 0 calc(var(--nav-size) * 0.15) rgba(255, 223, 0, 0.5),
    inset 0 0 calc(var(--nav-size) * 0.03) rgba(255, 255, 255, 0.8);
  animation: gold-shine 3s infinite alternate;
}

.gold-badge-circle::after {
  content: "★";
  color: rgba(95, 75, 5, 0.9);
  font-size: calc(var(--nav-size) * 0.2);
  font-weight: bold;
  text-shadow: 0 0 calc(var(--nav-size) * 0.03) rgba(255, 255, 255, 0.8);
}

@keyframes gold-shine {
  0%, 20% { 
    box-shadow: 
      0 calc(var(--nav-size) * 0.03) calc(var(--nav-size) * 0.06) rgba(0, 0, 0, 0.3),
      0 0 calc(var(--nav-size) * 0.08) rgba(255, 223, 0, 0.5),
      inset 0 0 calc(var(--nav-size) * 0.03) rgba(255, 255, 255, 0.8);
  }
  80%, 100% { 
    box-shadow: 
      0 calc(var(--nav-size) * 0.03) calc(var(--nav-size) * 0.06) rgba(0, 0, 0, 0.3),
      0 0 calc(var(--nav-size) * 0.15) rgba(255, 223, 0, 0.7),
      inset 0 0 calc(var(--nav-size) * 0.05) rgba(255, 255, 255, 0.9);
  }
}

/* Silver badge - Flex users */
.silver-badge-circle {
  background: linear-gradient(135deg, #e8e8e8 0%, #d8d8d8 25%, #c0c0c0 50%, #a9a9a9 75%, #b8b8b8 100%);
  border: calc(var(--nav-size) * 0.01) solid rgba(192, 192, 192, 0.8);
  box-shadow: 
    0 calc(var(--nav-size) * 0.03) calc(var(--nav-size) * 0.06) rgba(0, 0, 0, 0.2),
    0 0 calc(var(--nav-size) * 0.1) rgba(192, 192, 192, 0.5),
    inset 0 0 calc(var(--nav-size) * 0.03) rgba(255, 255, 255, 0.8);
  animation: silver-shine 4s infinite alternate;
}

@keyframes silver-shine {
  0%, 20% { 
    box-shadow: 
      0 calc(var(--nav-size) * 0.03) calc(var(--nav-size) * 0.06) rgba(0, 0, 0, 0.2),
      0 0 calc(var(--nav-size) * 0.06) rgba(192, 192, 192, 0.4);
  }
  80%, 100% { 
    box-shadow: 
      0 calc(var(--nav-size) * 0.03) calc(var(--nav-size) * 0.06) rgba(0, 0, 0, 0.2),
      0 0 calc(var(--nav-size) * 0.12) rgba(192, 192, 192, 0.6);
  }
}

/* Unknown badge - Free users */
.unknown-badge-circle {
  background: linear-gradient(135deg, #a3a3a3 0%, #696969 50%, #4a4a4a 100%);
  border: calc(var(--nav-size) * 0.01) solid rgba(105, 105, 105, 0.8);
  box-shadow: 
    0 calc(var(--nav-size) * 0.02) calc(var(--nav-size) * 0.01) rgba(0, 0, 0, 0.2),
    inset 0 0 calc(var(--nav-size) * 0.02) rgba(255, 255, 255, 0.5);
  opacity: 0.9;
}

.unknown-badge-circle::after {
  content: "•";
  color: rgba(220, 220, 220, 0.8);
  font-size: calc(var(--nav-size) * 0.3);
  line-height: 0.5;
  text-shadow: 0 0 calc(var(--nav-size) * 0.02) rgba(0, 0, 0, 0.5);
}

@keyframes shine {
  0% { transform: translateX(50%) rotate(45deg); }
  20%, 100% { transform: translateX(140%) rotate(45deg); }
}

/* Remove old triangle styles as they are no longer needed */
.gold-badge-triangle,
.silver-badge-triangle,
.unknown-badge-triangle {
  display: none;
}

@media (max-width: 768px) {
  .planit-dataresult-actions {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  
  .btn-text {
    display: none;
  }
  
  .planit-dataresult-header {
    flex-direction: column;
    align-items: flex-start;
    gap: calc(var(--nav-size) * 0.15);
  }
  
  .planit-dataresult-actions {
    align-self: flex-end;
  }
}
.planit-dataresult-1 {
}
.planit-dataresult-2 {
  margin-top: calc(var(--nav-size) * 0.1);
  margin-bottom: calc(var(--nav-size) * 0.1);
}
.planit-dataresult-3 {
  text-align: right;
}
.planit-dataresult-votecomment-holder {
  width: 100%;
  text-align: center;
}
.planit-dataresult-votecomment-link {
  width: calc(var(--nav-size) * 3.5);
  text-align: center;
  text-decoration: none;
  color: var(--text-color);
}
.planit-dataresult-votecomment {
  display: inline-block;
  text-align: center;
  width: calc(var(--nav-size) * 3.5);
  /* display: inline-block; */
  z-index: -1;
  font-size: calc(var(--nav-size) * 0.25);
  padding-bottom: calc(var(--nav-size) * 0.2);
  padding-top: calc(var(--nav-size) * 0.1);
}
.planit-dataresult-created {
  font-size: calc(var(--nav-size) * 0.25);
  display: inline-block;
  width: 50vw;
  text-align: left;
}
.planit-dataresult-share {
  width: 15vw;
  font-size: calc(var(--nav-size) * 0.25);
  display: inline-block;
}
.planit-dataresult-share-btn {
  background-color: transparent;
  font-size: calc(var(--nav-size) * 0.275);
  color: var(--text-color);
  vertical-align: middle;
  border: none;
  cursor: pointer;
}
.planit-dataresult-fav {
  width: 10vw;
  font-size: calc(var(--nav-size) * 0.25);
  vertical-align: middle;
  display: inline-block;
}
.planit-dataresult-fav-btn {
  background-color: transparent;
  border: none;
  font-size: calc(var(--nav-size) * 0.3);
  vertical-align: middle;
  color: var(--color-favorite);
  cursor: pointer;
}
.planit-dataresult-unfav-btn {
  font-size: calc(var(--nav-size) * 0.45);
  background-color: transparent;
  border: none;
  color: var(--color-unfavorite);
  vertical-align: middle;
  cursor: pointer;
}
.planit-dataresult-manageplan {
  width: 10vw;
  display: inline-block;
  /* padding-left: calc(var(--nav-size)*.05); */
}
.planit-dataresult-manageplan-btn {
  font-size: calc(var(--nav-size) * 0.35);
  vertical-align: middle;
  font-weight: bold;
  background-color: transparent;
  color: var(--text-color);
  border: none;
  cursor: pointer;
}
.planit-dataresult-goal {
  display: block;
}

.planit-dataresult-plan {
  display: block;
}
.planit-dataresult-planbutton {
  font-size: calc(var(--nav-size) * 0.25);
  font-weight: normal;
}
.planit-dataresult-disagree-div {
  position: absolute;
  left: calc(var(--nav-size) * 1);
}
.planit-dataresult-agree-div {
  position: absolute;
  right: calc(var(--nav-size) * 1);
}
.planit-dataresult-agree {
  display: inline-block;
  /* position: absolute; */
  /* right: calc(var(--nav-size)*5); */
  border: none;
  width: calc(var(--nav-size) * 0.6);
  height: calc(var(--nav-size) * 0.6);
  background-color: var(--white1-transp);

  border-radius: 50%;
  color: var(--text-color);
  cursor: pointer;
}
.planit-dataresult-agreeACT {
  display: inline-block;
  /* position: absolute; */
  /* right: calc(var(--nav-size)*5); */
  background-color: var(--green0);
  border: calc(var(--nav-size) * 0.001) solid var(--text-color);
  width: calc(var(--nav-size) * 0.6);
  height: calc(var(--nav-size) * 0.6);
  color: var(--dark-blue0);
  cursor: pointer;
  border-radius: 50%;
}
.planit-dataresult-disagree {
  display: inline-block;
  /* position: absolute; */
  /* left: calc(var(--nav-size)*1); */
  border: none;

  width: calc(var(--nav-size) * 0.6);
  height: calc(var(--nav-size) * 0.6);
  background-color: var(--white1-transp);
  color: var(--text-color);
  cursor: pointer;
  border-radius: 50%;
}
.planit-dataresult-disagreeACT {
  display: inline-block;
  /* position: absolute; */
  /* left: calc(var(--nav-size)*5); */
  background-color: var(--red1);
  border: calc(var(--nav-size) * 0.001) solid var(--text-color);

  width: calc(var(--nav-size) * 0.6);
  height: calc(var(--nav-size) * 0.6);
  border-radius: 50%;
  cursor: pointer;
  color: var(--text-color);
}
.planit-dataresult-thumb {
  width: calc(var(--nav-size) * 0.35);
  vertical-align: middle;
  text-align: center;
}
.planit-dataresult-plan {
  margin-top: calc(var(--nav-size) * 0.5);
  white-space: pre-line;
}
.planit-dataresult-goalbutton {
  max-width: 90%;
  border: none;
  background-color: transparent;
  color: var(--text-color);
  cursor: pointer;
  font-weight: bold;
}
.planit-dataresult-goalbutton-text {
  font-size: calc(var(--nav-size) * 0.3);
  white-space: normal;
  word-wrap: break-word;
}
.planit-dataresult-planbutton {
  border: none;
  background-color: transparent;
  color: var(--text-color);
  cursor: pointer;
}
.planit-dataresult-file-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.planit-dataresult-file-navigation button {
  background-color: var(--bg-nav2);
  color: var(--text-color);
  border: none;
  padding: 5px 10px;
  font-size: calc(var(--nav-size) * 0.3);
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.planit-dataresult-file-navigation button:hover {
  background-color: var(--bg-nav2-hover);
}

.planit-dataresult-created-user {
  display: inline-block;
  font-size: calc(var(--nav-size) * 0.25);
  margin-right: calc(var(--nav-size) * 0.1);
  vertical-align: middle;
}

.planit-dataresult-created-date {
  display: inline-block;
  font-size: calc(var(--nav-size) * 0.2);
  margin-right: calc(var(--nav-size) * 0.1);
  margin-left: calc(var(--nav-size) * 0.2);
  vertical-align: middle;
}

.planit-dataresult-badge {
  display: inline-block;
  background-color: var(--badge-color);
  color: var(--text-color-inv);
  font-size: calc(var(--nav-size) * 0.2);
  padding: calc(var(--nav-size) * 0.05) calc(var(--nav-size) * 0.1);
  border-radius: calc(var(--nav-size) * 0.05);
  margin-left: calc(var(--nav-size) * 0.05);
  vertical-align: middle;
}

.gold-badge, .silver-badge, .unknown-badge {
  display: inline-block;
  position: relative;
  width: calc(var(--nav-size) * 0.5);
  height: calc(var(--nav-size) * 0.5);
  margin-left: calc(var(--nav-size) * 0.1);
  margin-right: calc(var(--nav-size) * 0.1);
}

.gold-badge-circle, .silver-badge-circle, .unknown-badge-circle {
  width: calc(var(--nav-size) * 0.4);
  height: calc(var(--nav-size) * 0.4);
  border-radius: 50%;
  position: absolute;
  bottom: calc(var(--nav-size) * -0.15);
  left: 15%;
  filter:brightness(1.05);
}

.gold-badge-triangle {
  border-bottom-color: gold;
}

.gold-badge-circle {
  background-color: gold;
}

.silver-badge-triangle {
  border-bottom-color: silver;
}

.silver-badge-circle {
  background-color: silver;
}

.unknown-badge-triangle {
  border-bottom-color: gray;
}

.unknown-badge-circle {
  background-color: gray;
}

@media (orientation: landscape) {
  .planit-dataresult-disagree-div {
    position: absolute;
    left: 10%;
  }
  .planit-dataresult-agree-div {
    position: absolute;
    right: 10%;
  }
}
